import { RNFile } from './requests'
import dayjs from 'dayjs'

export interface PagedResponse<T> {
  count: number
  next?: string
  currentPage: number
  totalPages: number
  previous?: string
  results: T[]
}

export type GENDER = 'MALE' | 'FEMALE'
export const CustomerStatuses = ['ACTIVE', 'INACTIVE'] as CustomerStatus[]

export type Profile = {
  avatar: string | null
  file?: RNFile
  date_of_birth: string | null
  auth_type?: 'SOCIAL' | 'EMAIL'
  is_staff: boolean
  first_name: string | null
  gender: GENDER | null
  id?: string
  last_name: string | null
}

export type ID = {
  id: string
}

export type Url = {
  url: string
}

export type CodePushProgress = {
  totalBytes: number
  receivedBytes: number
  percent: number
}

// END OF RESPONSE TYPES
export type ActiveSubscriptions = {
  total: number
  api: number
  campaign: number
}
export type Signups = {
  today: number
  yesterday: number
  this_month: number
  last_month: number
}
export type Stats = {
  active_subscriptions: ActiveSubscriptions
  sign_ups: Signups
}

export enum BillingPeriod {
  'MONTHLY' = 1,
  'ANNUAL' = 2,
}

export const getBillingPeriodDescription = (v: BillingPeriod | undefined) => {
  if (!v) {
    return null
  }
  if (v === 1) {
    return 'Monthly'
  } else {
    return 'Annual'
  }
}

export type Address = {
  address_line_1: string
  address_line_2: string | null
  address_line_3?: string | null
  postal_code: string
  city: string
  country: string
}

export enum BusinessType {
  'LIMITED_COMPANY' = 'LIMITED_COMPANY',
  'SOLE_TRADER' = 'SOLE_TRADER',
  'CHARITY' = 'CHARITY',
  'UNINCORPORATED' = 'UNINCORPORATED',
  'TRUST' = 'TRUST',
  'ASSOCIATION' = 'ASSOCIATION',
}
export const businessTypeDescriptions: Record<BusinessType, string> = {
  ASSOCIATION: 'Association, club or society',
  CHARITY: 'Charity / non-profit',
  LIMITED_COMPANY: 'Limited company (LTD, LP, LLP, LLC, Corp)',
  SOLE_TRADER: 'Individual / sole trader',
  TRUST: 'Trust, foundation or fund',
  UNINCORPORATED: 'Unincorporated / not yet registered',
}
export type CustomerStatus = 'ACTIVE' | 'INACTIVE' | 'PENDING_INACTIVATION'
export type Document = {
  file_name: string
  id: number
  type: 'ID' | 'PROOF_OF_ADDRESS' | 'AUDIT_REPORT'
  url: string
}
export type Member = {
  id?: number
  documents?: Document[]
  first_name: string
  last_name: string
  date_of_birth: string
  phone_number?: string
  audit_report?: RNFile | null
  kyc_poa?: RNFile | null
  kyc_id?: RNFile | null
}
export type CompanySubscriptionDetail = {
  id?: number
  company_id?: number
  company_name: string | undefined
  email_address: string | undefined
  trading_name?: string | undefined
  contact_first_name: string | undefined
  contact_last_name: string | undefined
  company_number?: string | undefined
  business_type: BusinessType
  external_id?: string
  telephone_number?: string
  plan_id: number
  created_at?: string
  status: CustomerStatus
  address: Address
  members: Member[]
}
export enum APIBusinessType {
  'LIMITED_COMPANY' = 1,
  'SOLE_TRADER' = 3,
  'CHARITY' = 10,
  'UNINCORPORATED' = 9,
  'TRUST' = 12,
  'ASSOCIATION' = 13,
}
function getEnumKeyByValue<T>(
  enumObject: T,
  value: T[keyof T],
): keyof T | undefined {
  return Object.keys(enumObject as any).find(
    (key) => enumObject[key as keyof T] === value,
  ) as keyof T | undefined
}
export type APICompanySubscriptionDetail = {
  subscription_id?: string
  external_id?: string
  'product_id': number
  'customer': {
    'first_name': string
    'last_name': string
    'email_address': string
  }
  'shipping_address': {
    'shipping_address_line_1': string
    'shipping_address_line_2': string | null
    'shipping_address_line_3': string | null
    'shipping_address_city': string
    'shipping_address_postcode': string
    'shipping_address_country': string
  }
  'subscription': {
    'start_date': string
  }
  status: CustomerStatus
  'company': {
    'name': string
    'trading_name': string
    'limited_company_number': string
    'organisation_type': APIBusinessType
    'telephone_number': string
  }
  'members': CompanySubscriptionDetail['members']
}

export function convertSubscriptionToAPI(value: CompanySubscriptionDetail) {
  return {
    'company': {
      'limited_company_number': value.company_number,
      'name': value.company_name,
      'organisation_type': APIBusinessType[value.business_type] as any,
      'telephone_number': value.telephone_number,
      'trading_name': value.trading_name,
    },
    'customer': {
      'email_address': value.email_address,
      'first_name': value.contact_first_name,
      'last_name': value.contact_last_name,
    },
    external_id: value.external_id || undefined,
    'members': value.members,
    'product_id': value.plan_id,
    'shipping_address': {
      'shipping_address_city': value.address.city,
      'shipping_address_country': value.address.country,
      'shipping_address_line_1': value.address.address_line_1,
      'shipping_address_line_2': value.address.address_line_2,
      'shipping_address_line_3': value.address.address_line_3,
      'shipping_address_postcode': value.address.postal_code,
    },
    status: 'ACTIVE',
    'subscription': {
      'start_date': value.created_at || dayjs().format('YYYY-MM-DD'),
    },
    subscription_id: value.id,
  } as APICompanySubscriptionDetail
}

export function convertAPISubscriptionToNormal(
  value: APICompanySubscriptionDetail,
) {
  const res = {
    address: {
      address_line_1: value.shipping_address.shipping_address_line_1,
      address_line_2: value.shipping_address.shipping_address_line_2,
      address_line_3: value.shipping_address.shipping_address_line_3,
      city: value.shipping_address.shipping_address_city,
      country: value.shipping_address.shipping_address_country,
      postal_code: value.shipping_address.shipping_address_postcode,
    },
    business_type: getEnumKeyByValue(
      APIBusinessType,
      value.company.organisation_type,
    ),
    company_name: value.company.name,
    company_number: value.company.limited_company_number,
    contact_first_name: value.customer.first_name,
    contact_last_name: value.customer.last_name,
    created_at: value.subscription.start_date,
    email_address: value.customer.email_address,
    external_id: value.external_id,
    id: value.subscription_id,
    members: (value.members || []).map((v) => {
      const kyc_id = v.documents?.find((v) => v.type === 'ID')
      const kyc_poa = v.documents?.find((v) => v.type === 'PROOF_OF_ADDRESS')
      const audit_report = v.documents?.find((v) => v.type === 'AUDIT_REPORT')
      return {
        ...v,
        audit_report,
        kyc_id,
        kyc_poa,
      }
    }),
    plan_id: value.product_id,
    status: value.status,
    telephone_number: value.company.telephone_number,
    trading_name: value.company.trading_name,
  } as CompanySubscriptionDetail
  return res
}

export type CompanySubscriptionSummary = {
  address_line_1: string
  address_line_2: string | null
  address_line_3?: string | null
  company_id: number
  postal_code: string
  city: string
  country: string
  id: number
  company_name: string | undefined
  company_number?: string | undefined
  contact_name: string | undefined
  created_at: string
  email_address: string | undefined
  plan_id: number
  status: CustomerStatus
  telephone_number?: string
  trading_name?: string | undefined
}

export type PartnerProduct = {
  'id': 1
  'name': string
  'description': string
  'cost': number
  requires_kyc: 0 | 1
  billing_period: BillingPeriod
}
export type RoleSummary = {
  id: number
  name: 'PARTNER_ADMIN' | 'USER_WRITE' | 'USER_READ'
  description: string
}

export type MailStatus =
  | 'ACCOUNT_FROZEN'
  | 'COLLECTED'
  | 'CREATED'
  | 'DESTROYED'
  | 'FORWARDED'
  | 'ON_HOLD_INVOICE'
  | 'ON_HOLD_KYC_ID'
  | 'PENDING'
  | 'PROCESSING'
  | 'REFUSED'
  | 'REJECTED'
  | 'REQUESTED_FORWARD'
  | 'REQUESTED_SCAN'
  | 'RETURNED'
  | 'SCANNED'

export interface Mail {
  date: string
  expiry: string
  read: boolean
  address: string | null
  id: string
  processed_by: string
  status: MailStatus
  display_status: string
  company_id: string
  subscription_id: string
}

export type TeamMember = {
  id: number
  first_name: string
  last_name: string
  email: string
  role: string
  created_at: string
  status: 0 | 1
}
export type APIKey = {
  id: string
  name: string
  created_on: string
  enabled: boolean
}
export interface VerifyAccount {
  id_token: string
  token: string
  refresh_token: string
  expires_in: number
  first_name: string
  last_name: string
  email: string
  partner_id: number
  partner_name: string
}

export type Invoice = {
  id: string
  raised_date: string
  invoice_number: string
  period: string
  total_billing: number
  total_due: number
}
export type LineItem = {
  created_at: string
  amount: number
  id: string
  plan_id: number
  subscription: CompanySubscriptionSummary
}
export type InvoiceDetail = {
  id: string
  raised_date: string
  invoice_number: string
  period: string
  total_billing: number
  total_due: number
  commission_rate?: number
  line_items: LineItem[]
}
export type InvoiceDownload = {
  url: string
}
export type Res = {
  confirmEmail: ID
  forgotPassword: ID
  resendConfirmationEmail: {}
  resetPassword: {}
  setPassword: {}
  codepushProgress: CodePushProgress
  user: {
    id: number
    email: string
    tenant_id: number
    first_name: string
    last_name: string
    handles_kyc: boolean
    role: number
    partner_id: number
    partner_name: string
    products: PartnerProduct[]
    settings: {
      can_view_mail: boolean
      can_download_mail: boolean
      has_access_to_scans_in_the_mobile_application: boolean
    }
  }
  redirect: Url
  subscriptions: PagedResponse<CompanySubscriptionSummary>
  stats: Stats
  products: PartnerProduct[]
  subscription: CompanySubscriptionDetail
  company: PagedResponse<CompanySubscriptionDetail>
  teamMembers: PagedResponse<TeamMember>
  roles: RoleSummary[]
  teamMember: TeamMember
  apiKeys: PagedResponse<APIKey>
  apiKey: { id: string }
  cancelSubscription: { id: string }
  invoices: PagedResponse<Invoice>
  invoice: InvoiceDetail
  invoiceDownload: InvoiceDownload

  devSettings: {
    enabled: boolean
  }
  resendInvite: { id: string }
  ubo: Member
  ubos: Member[]
  uboDocument: { id: string }
  mail: PagedResponse<Mail>
  confirmAccount: { id: string }
  password: { id: string }
  mailDownload: {
    urls: string[]
  }
  resendMailItem: { id: string }
  reactivateSubscription: { id: string }
  // END OF TYPES
}
